import React, { useCallback, useMemo, useState } from 'react';
import { useApiContext } from 'src/contexts/firebase-api';
import Table from './Generics/Table/Table';
import styled from 'styled-components';
import PlayerUI from './Player';
import Seperator from './Generics/Seperator';
import Button from './Generics/Button';
import Box from './Generics/Box';
import Title from './Generics/Title';
import { Player } from 'src/api/use-players-api';

interface ChooseTeamProps {
  loading?: boolean;
  title?: string;
  onClose: () => void;
  onSubmit: (team: string[]) => Promise<void>;
  filter?: string[];
}

export default function ChooseTeam({
  loading,
  title = 'Choose a team',
  onClose,
  onSubmit,
  filter,
}: ChooseTeamProps) {
  const { players: _players } = useApiContext();
  const [selection, _setSelection] = useState([] as string[]);

  const players = useMemo(() => {
    return filter ? _players?.filter(p => !filter.includes(p.id)) : _players;
  }, [_players, filter]);

  const setSelection = useCallback((playerId: string) => {
    _setSelection(prev => {
      if (prev.includes(playerId)) {
        return prev.filter(id => id !== playerId);
      }
      return [...prev, playerId];
    });
  }, []);

  const onCancel = useCallback(() => {
    _setSelection([]);
    onClose();
  }, [onClose]);

  const onConfirm = useCallback(async () => {
    await onSubmit(selection);
    _setSelection([]);
  }, [onSubmit, selection]);

  return (
    <Box background="#2A3765" padding={0}>
      <Table<Player>
        title={
          <Box background="unset" borderRadius="unset" boxShadow="unset">
            <Title fontSize={32}>{title}</Title>
          </Box>
        }
        entries={players ?? []}
        renderEntry={({ entry: player }) => {
          if (!player) return <></>;

          return (
            <EntryCss>
              <PlayerUI
                playerId={player.id}
                canSelect={
                  selection.length < 2 || selection.includes(player.id)
                }
                selected={selection.includes(player.id)}
                select={() => setSelection(player.id)}
              />
            </EntryCss>
          );
        }}
      />
      <Seperator px={32} />
      <Box
        background="unset"
        borderRadius="unset"
        boxShadow="unset"
        paddingBottom="0px"
      >
        <ButtonsContainerCss>
          <Button
            height={36}
            backgroundColor="#E97474"
            text="Cancel"
            color="#121213"
            fontSize={24}
            onClick={onCancel}
            disabled={loading}
          />
          <Button
            height={36}
            disabled={
              !(selection.length === 1 || selection.length === 2) || loading
            }
            backgroundColor="#EBFD54"
            text="Confirm"
            color="#121213"
            fontSize={24}
            onClick={onConfirm}
            loading={loading}
          />
        </ButtonsContainerCss>
      </Box>
      <Seperator px={24} />
    </Box>
  );
}

const EntryCss = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
`;

const ButtonsContainerCss = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  align-items: center;
`;
