import React, { useCallback, useState } from 'react';
import Box from './Generics/Box';
import Title from './Generics/Title';
import Seperator from './Generics/Seperator';
import Text from './Generics/Text';
import Team from './Team';
import { useApiContext } from 'src/contexts/firebase-api';
import Button from './Generics/Button';
import styled from 'styled-components';

export default function NextChallengers() {
  const { user, nextChallengers, removeNextChallengers } = useApiContext();
  const [awaitingApi, setAwaitingApi] = useState(false);

  const onRemoveNextChallengers = useCallback(async () => {
    setAwaitingApi(true);
    await removeNextChallengers();
    setAwaitingApi(false);
  }, [removeNextChallengers]);

  const playerId = user?.player?.id;
  const userIsNextChallenger = playerId && nextChallengers?.includes(playerId);

  return (
    <Box>
      <HeaderCss>
        <Title fontSize={32}>Next Challengers</Title>
        {userIsNextChallenger && (
          <RetractButtonCss
            height={36}
            backgroundColor="#EBFD54"
            text="Retract coin"
            color="#121213"
            fontSize={24}
            onClick={onRemoveNextChallengers}
            loading={awaitingApi}
          />
        )}
      </HeaderCss>
      <Seperator px={24} />
      {!nextChallengers && <Text>No new challengers at the moment</Text>}
      {nextChallengers && (
        <>
          <Title fontSize={28}>Team</Title>
          <Seperator px={8} />
          <Team players={nextChallengers} />
        </>
      )}
    </Box>
  );
}

const HeaderCss = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  grid-gap: 10px;
  align-items: center;
  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
`;

const RetractButtonCss = styled(Button)`
  width: 135.344px;
  @media (max-width: 768px) {
    width: unset;
  }
`;
