import React, { MouseEvent } from 'react';
import Avatar from '@mui/material/Avatar';
import deepPurple from '@mui/material/colors/deepPurple';
import getInitials from 'src/helpers/get-initials';

interface AvatarOrInitialsProps {
  name?: string;
  avatar?: string;
  size?: number;
  classname?: string;
  styles?: React.CSSProperties;
  /** In rem */
  fontSize?: number;
  onClick?: (
    event: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
  ) => void;
}

export default function AvatarOrInitials({
  name,
  avatar,
  size,
  classname,
  styles,
  fontSize = 1.25,
  onClick,
}: AvatarOrInitialsProps) {
  const [, firstname, sirname] = getInitials(name ?? '');

  return (
    <Avatar
      src={avatar}
      className={classname}
      style={{
        ...styles,
        ...(size && { width: size, height: size }),
        backgroundColor: deepPurple[500],
      }}
      onClick={onClick}
      alt="Avatar"
    >
      {(!avatar || avatar.length < 1) && (
        <span style={{ fontSize: `${fontSize}rem` }}>
          {firstname}
          {sirname}
        </span>
      )}
    </Avatar>
  );
}
