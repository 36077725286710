import { onValue, ref } from 'firebase/database';
import { useCallback, useEffect, useState } from 'react';
import { FireBaseDB, InvokeFirebaseFunction } from 'src/App';
import lodash from 'lodash';

export interface Game {
  id: string;
  status: 'inProgress' | 'finished';
  home: { teamId: string; score: number };
  away: { teamId: string; score: number };
  fromCoinToss?: boolean;
  date: string;
  log: { action: 'create-game' | 'end-game'; uid: string; date: string }[];
}

interface NextGameParams {
  prevGame?: { homeScore: number; awayScore: number };
  nextGame?: {
    homePlayers?: string[];
    awayPlayers?: string[];
    challengers?: string[];
  };
}

export default function useGamesApi(
  firebaseDb: FireBaseDB,
  invokeFirebaseFunction: InvokeFirebaseFunction,
) {
  const [games, _setGames] = useState(undefined as Optional<Game[]>);

  useEffect(() => {
    onValue(ref(firebaseDb, 'games/'), snapshot => {
      const games = (snapshot.val() ?? []).filter(Boolean) as Optional<Game[]>;
      _setGames(lodash.orderBy(games, ['date'], ['desc']));
    });
  }, [firebaseDb]);

  const setNextGame = useCallback(
    async ({ prevGame, nextGame }: NextGameParams) => {
      await invokeFirebaseFunction('setNextGame', {
        prevGame,
        nextGame,
      });
    },
    [invokeFirebaseFunction],
  );

  return { games, setNextGame };
}
