import React from 'react';
import styled from 'styled-components';

export interface TableEntryChildren<T> {
  index: number;
  entry: T;
}

interface TableEntryProps<T> {
  backgroundColor: string;
  index: number;
  entry: T;
  children: (props: TableEntryChildren<T>) => JSX.Element;
}

export default function TableEntry<T>({
  backgroundColor,
  index,
  entry,
  children,
}: TableEntryProps<T>) {
  return (
    <ContainerCss backgroundColor={backgroundColor}>
      {children({ index, entry })}
    </ContainerCss>
  );
}

const ContainerCss = styled.div<{ backgroundColor: string }>`
  background-color: ${p => p.backgroundColor};
  padding: 8px 16px;
  align-items: center;
`;
