import * as React from 'react';
import { DataGrid, GridEventListener } from '@mui/x-data-grid';

interface DataTableProps<T> {
  columns: { field: string; headerName: string; width: number }[];
  rows: T[];
  width: number | string;
  height?: number | string;
  pageSize?: number;
  onRowClick?: GridEventListener<'rowClick'>;
}

export default function DataTable<T>({
  columns = [],
  rows = [],
  width = '100%',
  height = 700,
  pageSize = 10,
  onRowClick,
}: DataTableProps<T>) {
  return (
    <div style={{ width, height }}>
      <DataGrid
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rows={rows as any[]}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={[4]}
        onRowClick={onRowClick}
      />
    </div>
  );
}
