import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AvatarOrInitials from './AvatarOrInitials';
import styled from 'styled-components';
import Text from './Generics/Text';
import { useApiContext } from 'src/contexts/firebase-api';

export default function UserMenu() {
  const { user, signOut } = useApiContext();
  const [menuAnchor, setMenuAnchor] =
    useState<Nullable<HTMLButtonElement>>(null);

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(!menuAnchor ? event.currentTarget : null);
  };

  if (!user) {
    return null;
  }

  return (
    <ContainerCss>
      <Text fontSize={14} fontWeight={800}>
        {user?.player?.name}
      </Text>
      <IconButton
        aria-label="Show more"
        aria-haspopup="true"
        color="inherit"
        onClick={handleMenu}
      >
        <AvatarOrInitials
          name={user?.player?.name}
          avatar={user?.player?.avatar}
          styles={{ width: 50, height: 50, fontSize: 12 }}
        />

        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={handleMenu}
        >
          <MenuItem onClick={signOut}>Sign out</MenuItem>
        </Menu>
      </IconButton>
    </ContainerCss>
  );
}

const ContainerCss = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    justify-content: center;
    flex-direction: column-reverse;
  }
`;
