import { useCallback } from 'react';
import { useApiContext } from 'src/contexts/firebase-api';
import lodash from 'lodash';

export default function useTeamForm() {
  const { games } = useApiContext();

  const getTeamForm = useCallback(
    (id: string) => {
      const teamsGames = games?.filter(
        game => game.home.teamId === id || game.away.teamId === id,
      );
      const sorted = lodash.orderBy(teamsGames, ['date'], ['desc']);
      return [sorted[0], sorted[1], sorted[2], sorted[3], sorted[4]].map(
        game => {
          if (!game) {
            return '-';
          }

          const { home, away } = game;
          const homeTeamId = home.teamId;
          const awayTeamId = away.teamId;
          const homeIsWinner = home.score > away.score;
          const awayIsWinner = away.score > home.score;
          const draw = home.score === away.score;

          const isWinner =
            (homeTeamId === id && homeIsWinner) ||
            (awayTeamId === id && awayIsWinner);

          return draw ? '-' : isWinner ? 'win' : 'lost';
        },
      );
    },
    [games],
  );

  return getTeamForm;
}
