import React, { useMemo } from 'react';
import { useApiContext } from 'src/contexts/firebase-api';
import Table from './Generics/Table/Table';
import Text from './Generics/Text';
import TeamUI from './Team';
import useTeam from '../hooks/use-team';
import styled from 'styled-components';
import Box from './Generics/Box';
import { Game } from 'src/api/use-games-api';
import lodash from 'lodash';
import XIcon from './XIcon';

export default function TodaysGames() {
  const { games: _games } = useApiContext();
  const getTeam = useTeam();

  const games = useMemo(
    () =>
      lodash.orderBy(
        _games
          ?.filter(({ status }) => status === 'finished')
          .filter(({ date }) => {
            const today = new Date();
            const gameDate = new Date(date);
            return (
              today.getDate() === gameDate.getDate() &&
              today.getMonth() === gameDate.getMonth() &&
              today.getFullYear() === gameDate.getFullYear()
            );
          }),
        ['date'],
        ['desc'],
      ),
    [_games],
  );

  const header = useMemo(() => {
    return (
      <>
        <LeftHeaderColsCss>
          <Text justifySelf="flex-end">Team</Text>
        </LeftHeaderColsCss>
        <RightHeaderColsCss>
          <Text justifySelf="flex-end">Team</Text>
        </RightHeaderColsCss>
      </>
    );
  }, []);

  return (
    <Box>
      <Table<Optional<Game>>
        title="Today’s Matches"
        entries={games ?? []}
        header={header}
        renderEntry={({ entry: game }) => {
          if (!game) return <></>;

          const [home, away] = [
            getTeam(game.home.teamId),
            getTeam(game.away.teamId),
          ];
          const [homePlayers, awayPlayers] = [home?.players, away?.players];

          if (!homePlayers || !awayPlayers) return <></>;

          return (
            <EntryCss>
              <LeftEntryColsCss>
                <TeamUI players={homePlayers} />
                <Text color="#EBFD54" fontSize={16} fontWeight={800}>
                  {game.home.score}
                </Text>
              </LeftEntryColsCss>
              <XIcon size={24} />
              <RightEntryColsCss>
                <Text color="#EBFD54" fontSize={16} fontWeight={800}>
                  {game.away.score}
                </Text>
                <TeamUI players={awayPlayers} />
              </RightEntryColsCss>
            </EntryCss>
          );
        }}
      />
    </Box>
  );
}

const LeftHeaderColsCss = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: flex-start;
`;

const RightHeaderColsCss = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: flex-end;
`;

const EntryCss = styled.div`
  display: grid;
  grid-template-columns: 1fr 24px 1fr;
  grid-gap: 20px;
  justify-content: space-between;
  align-items: center;
`;

const LeftEntryColsCss = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
`;

const RightEntryColsCss = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
`;
