import { useCallback } from 'react';
import { useApiContext } from 'src/contexts/firebase-api';

export default function useTeam() {
  const { teams } = useApiContext();

  const getTeam = useCallback(
    (id?: string) => {
      return teams?.find(team => String(team.id) === String(id));
    },
    [teams],
  );

  return getTeam;
}
