import React, { useMemo } from 'react';
import styled from 'styled-components';
import Team from '../Team';
import useTeam from 'src/hooks/use-team';
import Title from '../Generics/Title';
import Text from '../Generics/Text';
import XIcon from '../XIcon';

interface HeadsUpProps {
  homeId?: string;
  awayId?: string;
  homeScore?: number;
  awayScore?: number;
  fromCoinToss?: boolean;
}

export default function HeadsUp({
  homeId,
  awayId,
  homeScore,
  awayScore,
  fromCoinToss,
}: HeadsUpProps) {
  const getTeam = useTeam();
  const homeTeam = useMemo(() => getTeam(homeId), [homeId, getTeam]);
  const awayTeam = useMemo(() => getTeam(awayId), [awayId, getTeam]);

  const homeTeamUI = useMemo(() => {
    const hasScore = typeof homeScore === 'number';

    return (
      <TeamContainerCss>
        <Title>
          {fromCoinToss
            ? homeTeam?.players.length === 1
              ? 'Defender'
              : 'Defenders'
            : 'Team 1'}
        </Title>
        {!hasScore && <Team {...homeTeam} />}
        {hasScore && (
          <TeamWithScoreCss>
            <Team {...homeTeam} />
            <Text color="#EBFD54" fontSize={32} fontWeight={800}>
              {homeScore}
            </Text>
          </TeamWithScoreCss>
        )}
      </TeamContainerCss>
    );
  }, [fromCoinToss, homeScore, homeTeam]);

  const awayTeamUI = useMemo(() => {
    const hasScore = typeof awayScore === 'number';

    return (
      <TeamContainerCss>
        <Title>
          {fromCoinToss
            ? awayTeam?.players.length === 1
              ? 'Challenger'
              : 'Challengers'
            : 'Team 2'}
        </Title>
        {!hasScore && <Team {...awayTeam} />}
        {hasScore && (
          <TeamWithScoreCss>
            <Text color="#EBFD54" fontSize={32} fontWeight={800}>
              {awayScore}
            </Text>
            <Team {...awayTeam} />
          </TeamWithScoreCss>
        )}
      </TeamContainerCss>
    );
  }, [awayScore, awayTeam, fromCoinToss]);

  if (!homeTeam || !awayTeam) return null;

  return (
    <HeadsUpContainerCss>
      {homeTeamUI}
      <XIcon size={24} />
      {awayTeamUI}
    </HeadsUpContainerCss>
  );
}

const HeadsUpContainerCss = styled.div`
  display: grid;
  grid-template-columns: 1fr 40px 1fr;
  grid-gap: 35px;
  justify-content: space-between;
  align-items: center;
`;

const TeamContainerCss = styled.div`
  text-align: center;
  margin: auto;
`;

const TeamWithScoreCss = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
  align-items: center;
`;
