import React from 'react';
import styled from 'styled-components';

export interface TextProps extends React.CSSProperties {
  onClick?: React.MouseEventHandler<HTMLParagraphElement> | undefined;
  children: React.ReactNode;
}

export default function Text({ onClick, children, ...params }: TextProps) {
  return (
    <TextCss
      onClick={onClick}
      style={
        {
          ...params,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any
      }
    >
      {children}
    </TextCss>
  );
}

const TextCss = styled.p`
  margin: 0.5em 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  font-feature-settings: 'ss01' on, 'ss02' on;
  color: #ffffff;
`;
