import React, { useMemo } from 'react';
import styled from 'styled-components';
import Seperator from '../Seperator';
import Title from '../Title';
import TableEntry, { TableEntryChildren } from './Entry';
import TableHeader from './Header';

interface TableProps<T> {
  title: string | JSX.Element;
  titleFontSize?: number;
  entries: T[];
  header?: JSX.Element;
  renderFilter?: () => JSX.Element;
  renderEntry: (props: TableEntryChildren<T>) => JSX.Element;
}

export default function Table<T>({
  title,
  titleFontSize,
  entries,
  header,
  renderFilter,
  renderEntry,
}: TableProps<T>) {
  const tableHeader = useMemo(() => {
    if (!header) return null;
    return <TableHeader>{header}</TableHeader>;
  }, [header]);

  const tableEntries = useMemo(() => {
    return entries.map((entry, index) => {
      return (
        <TableEntry
          key={index}
          index={index}
          backgroundColor={index % 2 === 0 ? '#121723' : '#2A3765'}
          entry={entry}
        >
          {renderEntry}
        </TableEntry>
      );
    });
  }, [renderEntry, entries]);

  return (
    <>
      <TitleCss hasFilter={!!renderFilter}>
        {typeof title === 'string' ? (
          <Title fontSize={titleFontSize ?? 32}>{title}</Title>
        ) : (
          title
        )}
        {renderFilter?.()}
      </TitleCss>
      <Seperator px={16} />
      {tableHeader}
      {tableEntries}
    </>
  );
}

const TitleCss = styled.div<{ hasFilter: boolean }>`
  ${p =>
    p.hasFilter &&
    `display: flex;align-items: center;justify-content: space-between;`}
`;
