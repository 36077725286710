import React, { useCallback, useMemo, useState } from 'react';
import { useApiContext } from 'src/contexts/firebase-api';
import ChooseTeam from './ChooseTeam';
import CoinTossAnimation from './CoinTossAnimation/CoinTossAnimation';
import DialogSlide from './Generics/DialogSlide';
import Background from '../assets/Background.svg';
import useTeam from 'src/hooks/use-team';

interface TossCoinProps {
  visible: boolean;
  onClose: () => void;
}

export default function TossCoin({ visible, onClose }: TossCoinProps) {
  const [awaitingApi, setAwaitingApi] = useState(false);
  const { games, setNextChallengers } = useApiContext();
  const [showCoinTossAnimation, setShowCoinTossAnimation] = useState(false);
  const getTeam = useTeam();

  const currentPlayers = useMemo(() => {
    const currentGame = games?.find(game => game.status === 'inProgress');
    return [currentGame?.home, currentGame?.away]
      .map(team => getTeam(team?.teamId)?.players)
      .flat(Infinity) as string[];
  }, [games, getTeam]);

  const onSubmit = useCallback(
    async (players: string[]) => {
      setAwaitingApi(true);
      await setNextChallengers(players);
      setShowCoinTossAnimation(true);
    },
    [setNextChallengers],
  );

  const onCoinTossAnimationComplete = useCallback(() => {
    setShowCoinTossAnimation(false);
    onClose();
    setAwaitingApi(false);
  }, [onClose]);

  return (
    <>
      <DialogSlide
        disableEscapeKeyDown={awaitingApi}
        sxPaper={{
          backgroundColor: 'transparent',
          padding: '0px',
          ...(showCoinTossAnimation && {
            boxShadow: 'none',
            backgroundImage: `url('${Background}')`,
            backgroundSize: 'cover',
            maxWidth: 'unset',
          }),
        }}
        fullWidth={!showCoinTossAnimation}
        fullScreen={showCoinTossAnimation}
        open={visible}
        onClose={onClose}
      >
        {!showCoinTossAnimation && (
          <ChooseTeam
            loading={awaitingApi}
            onClose={onClose}
            onSubmit={onSubmit}
            filter={currentPlayers}
          />
        )}
        {showCoinTossAnimation && (
          <CoinTossAnimation onComplete={onCoinTossAnimationComplete} />
        )}
      </DialogSlide>
    </>
  );
}
