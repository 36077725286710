import React, { useMemo } from 'react';
import styled from 'styled-components';
import Team from '../Team';
import useTeam from 'src/hooks/use-team';
import Title from '../Generics/Title';
import XIcon from '../XIcon';

interface HeadsUpWithInputProps {
  homeId?: string;
  awayId?: string;
  homeScore: Nullable<number>;
  awayScore: Nullable<number>;
  setHomeScore: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setAwayScore: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function HeadsUpWithInput({
  homeId,
  awayId,
  homeScore,
  awayScore,
  setHomeScore,
  setAwayScore,
}: HeadsUpWithInputProps) {
  const getTeam = useTeam();
  const homeTeam = useMemo(() => getTeam(homeId), [homeId, getTeam]);
  const awayTeam = useMemo(() => getTeam(awayId), [awayId, getTeam]);

  if (!homeTeam || !awayTeam) return null;

  return (
    <HeadsUpWithInputContainerCss>
      <TeamContainerCss>
        <TeamInnerContainerCss>
          <Title>Team 1</Title>
          <Team {...homeTeam} />
        </TeamInnerContainerCss>
        <ScoreInputCss
          type="number"
          value={homeScore ?? ''}
          onChange={setHomeScore}
        />
      </TeamContainerCss>
      <XIcon size={24} />
      <TeamContainerCss inverse>
        <ScoreInputCss
          type="number"
          value={awayScore ?? ''}
          onChange={setAwayScore}
        />
        <TeamInnerContainerCss>
          <Title>Team 2</Title>
          <Team {...awayTeam} />
        </TeamInnerContainerCss>
      </TeamContainerCss>
    </HeadsUpWithInputContainerCss>
  );
}

const HeadsUpWithInputContainerCss = styled.div`
  display: grid;
  grid-template-columns: auto 40px auto;
  grid-gap: 16px;
  justify-content: space-between;
  align-items: center;
`;

const TeamContainerCss = styled.div<{ inverse?: boolean }>`
  display: grid;
  grid-template-columns: ${p => (p.inverse ? '64px auto' : 'auto 64px')};
  grid-gap: 16px;
  align-items: center;
`;

const TeamInnerContainerCss = styled.div`
  text-align: center;
  background: #121723;
  padding: 12px 16px;
  border-radius: 8px;
`;

const ScoreInputCss = styled.input`
  width: 64px;
  height: 64px;
  background: #1b2437;
  border-radius: 8px;
  padding: 0;
  margin: 0;
  border: none;
  font-family: 'Switzer';
  font-style: italic;
  font-weight: 800;
  font-size: 32px;
  line-height: 150%;
  letter-spacing: -0.01em;
  font-feature-settings: 'ss01' on, 'ss02' on;
  color: #ffffff;
  text-align: center;
  outline: none;

  ::placeholder {
    color: #ffffff;
  }

  /* Chrome, Safari, Edge, Opera */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
