import React from 'react';
import styled, { css } from 'styled-components';
import removeIcon from '../assets/removeIcon.svg';

interface XIconProps {
  size: number;
  onClick?: () => void;
}

export default function XIcon({ size, onClick }: XIconProps) {
  return (
    <XCss
      src={removeIcon}
      size={size}
      onClick={onClick}
      withHover={!!onClick}
    />
  );
}

const XCss = styled.img<{ size: number; withHover: boolean }>`
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  ${({ withHover }) =>
    withHover &&
    css`
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        opacity: 0.54;
      }
    `}
`;
