import React from 'react';
import styled from 'styled-components';

interface TableHeaderProps {
  children: JSX.Element;
}

export default function TableHeader({ children }: TableHeaderProps) {
  return <ContainerCss>{children}</ContainerCss>;
}

const ContainerCss = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  background-color: #2a3765;
  padding: 8px 16px;
`;
