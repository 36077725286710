import {
  User,
  getAuth,
  onAuthStateChanged,
  signOut as firebaseSignOut,
} from 'firebase/auth';
import { useCallback, useEffect, useState } from 'react';
import { Player } from './use-players-api';

interface UserObject {
  firebaseUser: User;
  player?: Player;
}

export default function useUserApi(players: Player[]) {
  const [user, _setUser] = useState(undefined as Optional<UserObject>);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, firebaseUser => {
      if (firebaseUser) {
        const player = players?.find(
          player => player.email === firebaseUser.email,
        );
        _setUser({ firebaseUser, player });
      }
    });
  }, [players]);

  const signOut = useCallback(() => {
    const auth = getAuth();
    firebaseSignOut(auth)
      .then(() => {
        _setUser(undefined);
      })
      .catch(console.error);
  }, []);

  return { user, signOut };
}
