import { useCallback } from 'react';
import { useApiContext } from 'src/contexts/firebase-api';

export default function usePlayer() {
  const { players } = useApiContext();

  const getPlayer = useCallback(
    (id: string) => {
      return players?.find(player => player.id === id);
    },
    [players],
  );

  return getPlayer;
}
