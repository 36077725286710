import React from 'react';
import styled from 'styled-components';

interface SeperatorProps {
  px: number;
}

export default function Seperator({ px }: SeperatorProps) {
  return <SeperatorCss px={px} />;
}

const SeperatorCss = styled.div<{ px: number }>`
  width: 100%;
  height: ${p => p.px}px;
`;
