import { onValue, ref } from 'firebase/database';
import { useEffect, useState } from 'react';
import { FireBaseDB } from 'src/App';

export interface Player {
  id: string;
  name: string;
  avatar: string;
  email: string;
}

export default function usePlayersApi(firebaseDb: FireBaseDB) {
  const [players, _setPlayers] = useState([] as Player[]);

  useEffect(() => {
    onValue(ref(firebaseDb, 'players/'), snapshot => {
      const players = (snapshot.val() ?? []).filter(Boolean) as Optional<
        Player[]
      >;
      _setPlayers((players ?? []).sort((a, b) => a.name.localeCompare(b.name)));
    });
  }, [firebaseDb]);

  return { players };
}
