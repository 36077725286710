import React from 'react';

interface BoxProps extends React.CSSProperties {
  children: React.ReactNode;
}

export default function Box({ children, ...params }: BoxProps) {
  return (
    <div
      style={{
        padding: '16px',
        background: '#121723',
        borderRadius: '8px',
        boxShadow:
          '0px 0px 1px rgba(18, 23, 35, 0.05), 0px 12px 24px rgba(18, 23, 35, 0.1)',
        ...params,
      }}
    >
      {children}
    </div>
  );
}
