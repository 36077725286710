import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

interface SpinnerProps {
  size: number;
}

export default function Spinner({ size }: SpinnerProps) {
  return <CircularProgress size={size} sx={{ color: 'white !important' }} />;
}
