import React, { useCallback, useState } from 'react';
import Box from './Generics/Box';
import Button from './Generics/Button';
import TitleCss from './Generics/Title';
import Seperator from './Generics/Seperator';
import Text from './Generics/Text';
import TeamsPlaceholder from './HeadsUp/HeadsUpPlaceholder';
import { useBooleanState } from 'src/hooks/use-boolean-state';
import DialogSlide from './Generics/DialogSlide';
import ChooseTeam from './ChooseTeam';
import { useApiContext } from 'src/contexts/firebase-api';

export default function CreateGame() {
  const { setNextGame } = useApiContext();
  const [awaitingApi, setAwaitingApi] = useState(false);
  const [homePlayers, setHomePlayers] = useState([] as string[]);
  const [selectHomeTeam, showSelectHomeTeam, hideSelectHomeTeam] =
    useBooleanState(false);
  const [selectAwayTeam, showSelectAwayTeam, hideSelectAwayTeam] =
    useBooleanState(false);

  const onClose = useCallback(() => {
    setHomePlayers([]);
    hideSelectHomeTeam();
    hideSelectAwayTeam();
  }, [hideSelectAwayTeam, hideSelectHomeTeam]);

  const submitHomeTeam = useCallback(
    async (players: string[]) => {
      setHomePlayers(players);
      hideSelectHomeTeam();
      showSelectAwayTeam();
    },
    [hideSelectHomeTeam, showSelectAwayTeam],
  );

  const submit = useCallback(
    async (awayPlayers: string[]) => {
      setAwaitingApi(true);
      await setNextGame({
        nextGame: { homePlayers, awayPlayers },
      });

      hideSelectAwayTeam();
      setAwaitingApi(false);
    },
    [hideSelectAwayTeam, homePlayers, setNextGame],
  );

  return (
    <Box>
      <TitleCss fontSize={32} maxWidth="377px">
        Create Game
      </TitleCss>
      <Seperator px={8} />
      <Text fontSize={13} maxWidth="377px">
        Team up! Play a match against your colleagues and whose team is better.
        It is inevitable to happen!
      </Text>
      <Seperator px={24} />
      <TeamsPlaceholder />
      <Seperator px={24} />
      <Button
        height={36}
        backgroundColor="#EBFD54"
        text="Create a Game"
        color="#121213"
        fontSize={24}
        onClick={showSelectHomeTeam}
      />
      <DialogSlide
        disableEscapeKeyDown={awaitingApi}
        sxPaper={{
          backgroundColor: 'transparent',
          padding: '0px',
        }}
        fullWidth
        open={selectHomeTeam}
        onClose={onClose}
      >
        <ChooseTeam
          loading={awaitingApi}
          title="Choose home team"
          onClose={onClose}
          onSubmit={submitHomeTeam}
        />
      </DialogSlide>
      <DialogSlide
        disableEscapeKeyDown={awaitingApi}
        sxPaper={{
          backgroundColor: 'transparent',
          padding: '0px',
        }}
        fullWidth
        open={selectAwayTeam}
        onClose={onClose}
      >
        <ChooseTeam
          loading={awaitingApi}
          title="Choose away team"
          onClose={onClose}
          onSubmit={submit}
          filter={homePlayers}
        />
      </DialogSlide>
    </Box>
  );
}
