import React from 'react';
import Lottie from 'react-lottie-player';
import animationData from './animationData.json';

interface CoinTossAnimationProps {
  onComplete: () => void;
}

export default function CoinTossAnimation({
  onComplete,
}: CoinTossAnimationProps) {
  return (
    <Lottie
      animationData={animationData}
      play
      loop={false}
      style={{ width: 612, height: 612, margin: 'auto' }}
      onComplete={onComplete}
    />
  );
}
