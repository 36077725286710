import React from 'react';
import styled from 'styled-components';
import Title from '../Generics/Title';
import Text from '../Generics/Text';

export default function HeadsUpPlaceholder() {
  return (
    <HeadsUpPlaceholderContainerCss>
      <TeamContainerCss>
        <Title>Team 1</Title>
        <Text>No players currently</Text>
      </TeamContainerCss>
      <TeamContainerCss>
        <Title>Team 2</Title>
        <Text>No players currently</Text>
      </TeamContainerCss>
    </HeadsUpPlaceholderContainerCss>
  );
}

const HeadsUpPlaceholderContainerCss = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
`;

const TeamContainerCss = styled.div`
  text-align: center;
`;
