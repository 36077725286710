import React, { useCallback, useMemo, useState } from 'react';
import { useApiContext } from 'src/contexts/firebase-api';
import Text from './Generics/Text';
import Table from './Generics/Table/Table';
import useTeamForm from 'src/hooks/use-team-form';
import styled from 'styled-components';
import TeamUI from './Team';
import Box from './Generics/Box';
import { Team } from 'src/api/use-teams-api';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from './Generics/Tooltip';
import WithHover from './Generics/WithHover';
import SideBySide from './SideBySide';

export default function Leaderboard() {
  const { teams: _teams } = useApiContext();
  const [filter, setFilter] = useState('players' as 'players' | 'teams');
  const getTeamForm = useTeamForm();

  const teams = useMemo(
    () =>
      (_teams ?? [])
        .sort((a, b) => b.points - a.points)
        .filter(team => team.matches > 0)
        .filter(team => {
          if (filter === 'players') return team.players.length === 1;
          if (filter === 'teams') return team.players.length > 1;
          return false;
        }),
    [_teams, filter],
  );

  const header = useMemo(() => {
    return (
      <>
        <LeftHeaderColsCss>
          <Text>#</Text>
          <Text>Team</Text>
        </LeftHeaderColsCss>
        <RightHeaderColsCss>
          <Text>Form</Text>
          <Text>M</Text>
          <Text>W</Text>
          <Text>D</Text>
          <Text>L</Text>
          <Text>Pts.</Text>
        </RightHeaderColsCss>
      </>
    );
  }, []);

  const renderSideBySideButton = useCallback((openSideBySide: () => void) => {
    return (
      <WithHover>
        <SearchIcon sx={{ color: '#ffffff' }} onClick={openSideBySide} />
      </WithHover>
    );
  }, []);

  return (
    <Box>
      <Table<Optional<Team>>
        title="Leaderboard"
        renderFilter={() => {
          return (
            <FilterContainerCss>
              <SideBySide>{renderSideBySideButton}</SideBySide>
              {['players', 'teams'].map(f => {
                const props =
                  f === filter
                    ? {
                        sx: { color: '#4ae1e2' },
                      }
                    : {
                        onClick: () => setFilter(f as typeof filter),
                        sx: { color: '#ffffff' },
                      };

                return (
                  <Tooltip
                    key={f}
                    title={f.charAt(0).toUpperCase() + f.slice(1)}
                  >
                    <WithHover>
                      {f === 'players' && <PersonIcon key={f} {...props} />}
                      {f === 'teams' && <PeopleAltIcon key={f} {...props} />}
                    </WithHover>
                  </Tooltip>
                );
              })}
            </FilterContainerCss>
          );
        }}
        entries={teams}
        header={header}
        renderEntry={({ index, entry: team }) => {
          if (!team) return <></>;

          const form = getTeamForm(team.id);

          return (
            <EntryCss>
              <LeftEntryColsCss>
                <Text>{index + 1}</Text>
                <TeamUI avatarSize={24} fontSize={16} {...team} />
              </LeftEntryColsCss>
              <RightEntryColsCss>
                <TeamFormCss>
                  {form.map((match, index) => (
                    <TeamFormBoxCss
                      key={index}
                      color={
                        match === 'win'
                          ? '#4ac966'
                          : match === '-'
                          ? 'grey'
                          : '#E97474'
                      }
                    />
                  ))}
                </TeamFormCss>
                <Text>{team.matches}</Text>
                <Text>{team.wins}</Text>
                <Text>{team.draws}</Text>
                <Text>{team.losses}</Text>
                <Text>{team.points}</Text>
              </RightEntryColsCss>
            </EntryCss>
          );
        }}
      />
    </Box>
  );
}

const FilterContainerCss = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 8px;
`;

const TeamFormCss = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 12px);
  grid-gap: 4px;
  margin: auto;
`;

const TeamFormBoxCss = styled.div`
  width: 12px;
  height: 12px;
  background: ${p => p.color};
`;

const LeftHeaderColsCss = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 8px;
  justify-content: flex-start;
`;

const RightHeaderColsCss = styled.div`
  display: grid;
  grid-template-columns: 100px repeat(5, 15px);
  grid-gap: 8px;
  justify-content: center;
  text-align: center;
`;

const EntryCss = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
`;

const LeftEntryColsCss = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 8px;
  justify-content: flex-start;
  align-items: center;
`;

const RightEntryColsCss = styled.div`
  display: grid;
  grid-template-columns: 100px repeat(5, 15px);
  grid-gap: 8px;
  justify-content: center;
  align-items: center;
`;
