import React, { createContext, useContext, useMemo } from 'react';
import useGamesApi, { Game } from 'src/api/use-games-api';
import useNextChallengersApi from 'src/api/use-next-challengers-api';
import usePlayersApi, { Player } from 'src/api/use-players-api';
import useTeamsApi, { Team } from 'src/api/use-teams-api';
import useUserApi from 'src/api/use-user';
import {
  FirebaseAuth as FirebaseAuthType,
  FirebaseAuthUI,
  FireBaseDB,
  InvokeFirebaseFunction,
} from 'src/App';
import FirebaseAuth from 'src/components/FirebaseAuth';

interface ApiContext {
  user: ReturnType<typeof useUserApi>['user'];
  signOut: ReturnType<typeof useUserApi>['signOut'];
  nextChallengers?: string[];
  teams?: Team[];
  players?: Player[];
  games?: Game[];
  setNextGame: ReturnType<typeof useGamesApi>['setNextGame'];
  setNextChallengers: ReturnType<
    typeof useNextChallengersApi
  >['setNextChallengers'];
  removeNextChallengers: ReturnType<
    typeof useNextChallengersApi
  >['removeNextChallengers'];
}

const apiContext = createContext<ApiContext>({
  user: undefined,
  signOut: () => undefined,
  nextChallengers: undefined,
  teams: undefined,
  players: undefined,
  games: undefined,
  setNextGame: async () => undefined,
  setNextChallengers: async () => undefined,
  removeNextChallengers: async () => undefined,
});

export const useApiContext = () => useContext(apiContext);

interface ApiProviderProps {
  firebaseAuth: FirebaseAuthType;
  firebaseAuthUI: FirebaseAuthUI;
  firebaseDb: FireBaseDB;
  invokeFirebaseFunction: InvokeFirebaseFunction;
  children: React.ReactNode;
}

export default function FirebaseApiProvider({
  firebaseAuth,
  firebaseAuthUI,
  firebaseDb,
  invokeFirebaseFunction,
  children,
}: ApiProviderProps) {
  const { games, setNextGame } = useGamesApi(
    firebaseDb,
    invokeFirebaseFunction,
  );
  const { teams } = useTeamsApi(firebaseDb);
  const { players } = usePlayersApi(firebaseDb);
  const { user, signOut } = useUserApi(players);
  const { nextChallengers, setNextChallengers, removeNextChallengers } =
    useNextChallengersApi(firebaseDb, invokeFirebaseFunction);

  return (
    <apiContext.Provider
      value={useMemo(() => {
        return {
          user,
          signOut,
          nextChallengers,
          teams,
          players,
          games,
          setNextGame,
          setNextChallengers,
          removeNextChallengers,
        };
      }, [
        user,
        signOut,
        nextChallengers,
        teams,
        players,
        games,
        setNextGame,
        setNextChallengers,
        removeNextChallengers,
      ])}
    >
      <FirebaseAuth firebaseAuth={firebaseAuth} firebaseAuthUI={firebaseAuthUI}>
        {children}
      </FirebaseAuth>
    </apiContext.Provider>
  );
}
