import React, { useCallback, useMemo, useState } from 'react';
import DialogSlide from '../Generics/DialogSlide';
import { useBooleanState } from 'src/hooks/use-boolean-state';
import { useApiContext } from 'src/contexts/firebase-api';
import DataTable from '../Generics/DataTable';
import useTeam from 'src/hooks/use-team';
import usePlayer from 'src/hooks/use-player';
import moment from 'moment';
import { GridEventListener } from '@mui/x-data-grid';
import LogDetails from './Details';
import { Game } from 'src/api/use-games-api';
import styled from 'styled-components';
import Button from '../Generics/Button';

interface LogProps {
  children: (showLog: () => void) => React.ReactNode;
  games?: Game[];
}

export default function Log({
  games: propsGames,
  children: _children,
}: LogProps) {
  const { games: apiGames } = useApiContext();
  const [logVisible, showLog, hideLog] = useBooleanState(false);
  const [gameDetails, setGameDetails] = useState(undefined as Optional<Game>);
  const getTeam = useTeam();
  const getPlayer = usePlayer();

  const games = propsGames ?? apiGames;

  const children = useMemo(() => _children(showLog), [_children, showLog]);

  const { columns, rows } = useMemo(() => {
    return {
      columns: [
        { field: 'id', headerName: 'Game ID', width: 130 },
        { field: 'homePlayers', headerName: 'Home Players', width: 250 },
        { field: 'awayPlayers', headerName: 'Away Players', width: 250 },
        { field: 'homeScore', headerName: 'Home Score', width: 130 },
        { field: 'awayScore', headerName: 'Away Score', width: 130 },
        { field: 'date', headerName: 'Date', width: 250 },
      ],
      rows:
        games
          ?.filter(game => !!game.id)
          ?.map(game => {
            const [homePlayers, awayPlayers] = [
              game.home.teamId,
              game.away.teamId,
            ].map(
              teamId =>
                getTeam(teamId)
                  ?.players.map(playerId => getPlayer(playerId)?.name)
                  .join(', ') ?? [],
            );

            moment.locale('da');

            return {
              id: game.id,
              homePlayers,
              awayPlayers,
              homeScore: game.home.score,
              awayScore: game.away.score,
              date: moment(game.date).format('lll'),
            };
          }) ?? [],
    };
  }, [games, getPlayer, getTeam]);

  const onRowClick = useCallback(
    params => {
      setGameDetails(games?.find(game => game.id === params.row.id));
    },
    [games],
  ) as GridEventListener<'rowClick'>;

  const hideGameDetails = useCallback(() => {
    setGameDetails(undefined);
  }, []);

  return (
    <>
      <DialogSlide open={logVisible} onClose={hideLog} fullScreen>
        <DataTable
          width="100%"
          height="100%"
          columns={columns}
          rows={rows}
          onRowClick={onRowClick}
        />
        <ButtonContainerCss>
          <Button
            height={36}
            backgroundColor="#E97474"
            text="Close"
            color="#121213"
            fontSize={24}
            onClick={hideLog}
          />
        </ButtonContainerCss>
      </DialogSlide>
      <DialogSlide
        open={!!gameDetails}
        onClose={hideGameDetails}
        fullWidth
        sxPaper={{ padding: '60px' }}
      >
        <LogDetails game={gameDetails} />
      </DialogSlide>
      {children}
    </>
  );
}

const ButtonContainerCss = styled.div`
  width: 200px;
  margin: auto;
`;
