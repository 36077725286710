import { onValue, ref } from 'firebase/database';
import { useEffect, useState } from 'react';
import { FireBaseDB } from 'src/App';

export interface Team {
  id: string;
  name?: string;
  players: string[];
  matches: number;
  wins: number;
  draws: number;
  losses: number;
  points: number;
}

export default function useTeamsApi(firebaseDb: FireBaseDB) {
  const [teams, _setTeams] = useState(undefined as Optional<Team[]>);

  useEffect(() => {
    onValue(ref(firebaseDb, 'teams/'), snapshot => {
      const teams = (snapshot.val() ?? []).filter(Boolean) as Optional<Team[]>;
      _setTeams(teams);
    });
  }, [firebaseDb]);

  return {
    teams,
  };
}
