import React, { useEffect, useMemo, useState } from 'react';
import { onAuthStateChanged, EmailAuthProvider } from 'firebase/auth';
import 'firebaseui/dist/firebaseui.css';
import DialogSlide from './Generics/DialogSlide';
import { FirebaseAuth as FirebaseAuthType, FirebaseAuthUI } from 'src/App';

interface AuthProps {
  firebaseAuth: FirebaseAuthType;
  firebaseAuthUI: FirebaseAuthUI;
  children: React.ReactNode;
}

export default function FirebaseAuth({
  firebaseAuth,
  firebaseAuthUI,
  children,
}: AuthProps) {
  const [showAuthUI, setShowAuthUI] = useState(false);

  const uiConfig = useMemo(
    () => ({
      callbacks: {
        signInSuccessWithAuthResult: function () {
          setShowAuthUI(false);
          return false;
        },
      },
      signInOptions: [
        {
          provider: EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: false,
        },
      ],
      tosUrl: '/tos',
      privacyPolicyUrl: '/privacy',
    }),
    [],
  );

  const transitionProps = useMemo(
    () => ({
      onEntered: () => {
        firebaseAuthUI.start('#firebaseui-auth-container', uiConfig);
      },
    }),
    [firebaseAuthUI, uiConfig],
  );

  useEffect(() => {
    onAuthStateChanged?.(firebaseAuth, user => {
      if (user) {
        setShowAuthUI(false);
      } else {
        setShowAuthUI(true);
      }
    });
  }, [firebaseAuth]);

  return (
    <>
      <DialogSlide open={showAuthUI} transitionProps={transitionProps}>
        <div id="firebaseui-auth-container" />
      </DialogSlide>
      {children}
    </>
  );
}
