import React, { useMemo } from 'react';
import styled from 'styled-components';
import Box from './Generics/Box';
import Button from './Generics/Button';
import TitleCss from './Generics/Title';
import Seperator from './Generics/Seperator';
import Text from './Generics/Text';
import { useApiContext } from 'src/contexts/firebase-api';
import CreateGame from './CreateGame';
import { useBooleanState } from 'src/hooks/use-boolean-state';
import FinishGame from './FinishGame';
import TossCoin from './TossCoin';
import Teams from './HeadsUp/HeadsUp';

export default function CurrentGame() {
  const { games, nextChallengers } = useApiContext();
  const [tossCoinVisible, showTossCoin, hideTossCoin] = useBooleanState(false);
  const [finishGameVisible, showFinishGame, hideFinishGame] =
    useBooleanState(false);

  const currentGame = useMemo(
    () => games?.find(({ status }) => status === 'inProgress'),
    [games],
  );

  if (!currentGame) return <CreateGame />;

  return (
    <>
      <Box>
        <TitleCss fontSize={32} maxWidth="377px">
          Current Game
        </TitleCss>
        <Seperator px={8} />
        <Text fontSize={13} maxWidth="377px">
          Find yourself a teammate and challenge the winners! Don’t let them
          have all the fun and glory!
        </Text>
        <Seperator px={24} />
        <Teams
          homeId={currentGame.home.teamId}
          awayId={currentGame.away.teamId}
          fromCoinToss={currentGame.fromCoinToss}
        />
        <Seperator px={24} />
        <ButtonsContainerCss>
          <Button
            height={36}
            backgroundColor="#E97474"
            text="Finish Game"
            color="#121213"
            fontSize={24}
            onClick={showFinishGame}
          />
          <Button
            height={36}
            disabled={!!nextChallengers}
            backgroundColor="#EBFD54"
            text="Toss a coin"
            color="#121213"
            fontSize={24}
            onClick={showTossCoin}
          />
        </ButtonsContainerCss>
      </Box>
      <FinishGame
        visible={finishGameVisible}
        onClose={hideFinishGame}
        homeId={currentGame.home.teamId}
        awayId={currentGame.away.teamId}
      />
      <TossCoin visible={tossCoinVisible} onClose={hideTossCoin} />
    </>
  );
}

const ButtonsContainerCss = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  align-items: center;
`;
