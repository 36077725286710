import React from 'react';
import MUITooltip from '@mui/material/Tooltip';

interface TooltipProps {
  title?: string;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  children: React.ReactElement;
}

export default function Tooltip({ title, placement, children }: TooltipProps) {
  return (
    <MUITooltip title={title} placement={placement}>
      <span>{children}</span>
    </MUITooltip>
  );
}
