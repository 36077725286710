import React, { useMemo } from 'react';
import { Game } from 'src/api/use-games-api';
import usePlayer from 'src/hooks/use-player';
import styled from 'styled-components';
import HeadsUp from '../HeadsUp/HeadsUp';
import moment from 'moment';
import lodash from 'lodash';
import DataTable from '../Generics/DataTable';

interface LogProps {
  game?: Game;
}

export default function LogDetails({ game }: LogProps) {
  const getPlayer = usePlayer();

  const { columns, rows } = useMemo(() => {
    return {
      columns: [
        { field: 'uid', headerName: 'User ID', width: 130 },
        { field: 'uname', headerName: 'User Name', width: 130 },
        { field: 'action', headerName: 'Action', width: 250 },
        { field: 'date', headerName: 'Date', width: 250 },
      ],
      rows:
        lodash.orderBy(game?.log ?? [], ['date'], ['desc'])?.map(log => {
          moment.locale('da');

          return {
            ...log,
            id: log.uid + log.date,
            uname: getPlayer(log.uid)?.name ?? '',
          };
        }) ?? [],
    };
  }, [game, getPlayer]);

  if (!game) return null;

  return (
    <LogDetailsCss>
      <DataTable width="100%" height={700} columns={columns} rows={rows} />
    </LogDetailsCss>
  );
}

const LogDetailsCss = styled.div``;
