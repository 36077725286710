export default function getInitials(usersName = '') {
  const names = usersName.split(' ') || [];
  const initials = (names || [])
    .map((name: string) => name.slice(0, 1))
    .join('');
  const firstname = (names[0] || '').slice(0, 1);
  const sirname = (names[names.length - 1] || '').slice(0, 1);

  return [initials, firstname, sirname] as const;
}
