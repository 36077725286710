import React from 'react';
import lodash from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { Game } from 'src/api/use-games-api';
import { useApiContext } from 'src/contexts/firebase-api';
import useTeam from 'src/hooks/use-team';
import styled from 'styled-components';
import ChooseTeam from './ChooseTeam';
import Box from './Generics/Box';
import DialogSlide from './Generics/DialogSlide';
import Text from './Generics/Text';
import TeamUI from './Team';
import Log from './Log/Log';
import WithHover from './Generics/WithHover';

interface Statistics {
  team1: { id: string; wins: number; score: number };
  draws: number;
  team2: { id: string; wins: number; score: number };
  games: string[];
  log: Game[];
}

const sumScore = (games: Game[], team: string) => {
  return games.reduce((acc, game) => {
    if (game.home.teamId === team) {
      return acc + game.home.score;
    }

    if (game.away.teamId === team) {
      return acc + game.away.score;
    }

    return acc;
  }, 0);
};

interface SideBySideProps {
  children: (openSideBySide: () => void) => React.ReactNode;
}

export default function SideBySide({ children: _children }: SideBySideProps) {
  const { games, teams } = useApiContext();
  const getTeam = useTeam();
  const [screen, setScreen] = useState(
    'hidden' as 'hidden' | 'team1' | 'team2' | 'stats',
  );
  const [team1, _setTeam1] = useState([] as string[]);
  const [stats, setStats] = useState(null as Nullable<Statistics>);

  const getTeamId = useCallback(
    (team: string[]) => {
      const teamId = teams?.find(
        t =>
          t.players.every(player => team.includes(player)) &&
          t.players.length === team.length,
      )?.id;

      if (!teamId) {
        throw new Error('Team not found');
      }

      return teamId;
    },
    [teams],
  );

  const getStatistics = useCallback(
    (team1: string[], team2: string[]) => {
      const team1Id = getTeamId(team1);
      const team2Id = getTeamId(team2);

      const _games = lodash.orderBy(
        (games ?? [])
          .filter(game => {
            const teamIds = [game.home.teamId, game.away.teamId];
            return teamIds.includes(team1Id) && teamIds.includes(team2Id);
          })
          .map(game => {
            const draw = game.home.score === game.away.score;
            const winner = draw
              ? 'draw'
              : game.home.score > game.away.score
              ? game.home.teamId
              : game.away.teamId;

            return { ...game, winner };
          }),
        ['date'],
        ['desc'],
      );

      const team1Winner = _games?.filter(game => game.winner === team1Id);
      const team2Winner = _games?.filter(game => game.winner === team2Id);
      const draws = _games?.filter(game => game.winner === 'draw');

      return {
        team1Winner,
        team2Winner,
        draws,
        games: _games,
      };
    },
    [games, getTeamId],
  );

  const clear = useCallback(() => {
    _setTeam1([]);
    setStats(null);
    setScreen('hidden');
  }, []);

  const setTeam1 = useCallback(async (team: string[]) => {
    _setTeam1(team);
    setScreen('team2');
  }, []);

  const setTeam2 = useCallback(
    async (team2: string[]) => {
      const statistics = getStatistics(team1, team2);
      setTeam1([]);

      const team1Id = getTeamId(team1);
      const team2Id = getTeamId(team2);

      setStats({
        team1: {
          id: team1Id,
          wins: statistics?.team1Winner.length,
          score: sumScore(statistics.games, team1Id),
        },
        draws: statistics?.draws.length ?? 0,
        team2: {
          id: team2Id,
          wins: statistics?.team2Winner.length,
          score: sumScore(statistics.games, team2Id),
        },
        games: statistics?.games.map(game => game.winner),
        log: statistics?.games,
      });

      setScreen('stats');
    },
    [getStatistics, getTeamId, setTeam1, team1],
  );

  const team1Players = useMemo(() => {
    return getTeam(stats?.team1.id)?.players;
  }, [getTeam, stats?.team1.id]);

  const team2Players = useMemo(() => {
    return getTeam(stats?.team2.id)?.players;
  }, [getTeam, stats?.team2.id]);

  const renderLogButton = useCallback((showLog: () => void) => {
    return (
      <LogButtonContainer>
        <WithHover>
          <Text
            color="#EBFD54"
            fontSize={16}
            fontWeight={800}
            onClick={showLog}
          >
            Open log
          </Text>
        </WithHover>
      </LogButtonContainer>
    );
  }, []);

  const children = useMemo(
    () => _children(() => setScreen('team1')),
    [_children],
  );

  return (
    <>
      <DialogSlide
        sxPaper={{
          backgroundColor: 'transparent',
          padding: '0px',
        }}
        fullWidth
        open={screen === 'team1'}
        onClose={clear}
      >
        <ChooseTeam
          title="Choose first team"
          onClose={clear}
          onSubmit={setTeam1}
        />
      </DialogSlide>
      <DialogSlide
        sxPaper={{
          backgroundColor: 'transparent',
          padding: '0px',
        }}
        fullWidth
        open={screen === 'team2'}
        onClose={clear}
      >
        <ChooseTeam
          title="Choose second team"
          onClose={clear}
          onSubmit={setTeam2}
          filter={team1}
        />
      </DialogSlide>
      <DialogSlide
        sxPaper={{
          backgroundColor: 'transparent',
          padding: '0px',
        }}
        fullWidth
        open={screen === 'stats'}
        onClose={clear}
      >
        <Box background="#2A3765" padding={20}>
          <EntryCss>
            <TeamUI players={team1Players} />
            <StatsCss>
              <StatsEntryCss>
                <Text fontSize={16} fontWeight={800}>
                  Score
                </Text>
                <Text fontSize={16} fontWeight={800}>
                  {stats?.team1.score}
                </Text>
              </StatsEntryCss>
              <StatsEntryCss>
                <Text color="#EBFD54" fontSize={16} fontWeight={800}>
                  Wins
                </Text>
                <Text color="#EBFD54" fontSize={16} fontWeight={800}>
                  {stats?.team1.wins}
                </Text>
              </StatsEntryCss>
              <StatsEntryCss>
                <Text color="#EBFD54" fontSize={16} fontWeight={800}>
                  Draws
                </Text>
                <Text color="#EBFD54" fontSize={16} fontWeight={800}>
                  {stats?.draws}
                </Text>
              </StatsEntryCss>
              <StatsEntryCss>
                <Text color="#EBFD54" fontSize={16} fontWeight={800}>
                  Wins
                </Text>
                <Text color="#EBFD54" fontSize={16} fontWeight={800}>
                  {stats?.team2.wins}
                </Text>
              </StatsEntryCss>
              <StatsEntryCss>
                <Text fontSize={16} fontWeight={800}>
                  Score
                </Text>
                <Text fontSize={16} fontWeight={800}>
                  {stats?.team2.score}
                </Text>
              </StatsEntryCss>
            </StatsCss>
            <TeamUI players={team2Players} />
          </EntryCss>
          <Log games={stats?.log}>{renderLogButton}</Log>
        </Box>
      </DialogSlide>
      {children}
    </>
  );
}

const EntryCss = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 20px;
  justify-content: space-between;
  align-items: center;
`;

const StatsCss = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
`;

const StatsEntryCss = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogButtonContainer = styled.div`
  display: flex;
  margin-top: 50px;
  justify-content: center;
`;
