import { onValue, ref } from 'firebase/database';
import { useCallback, useEffect, useState } from 'react';
import { FireBaseDB, InvokeFirebaseFunction } from 'src/App';

export type NextChallenger = string;

export default function useNextChallengersApi(
  firebaseDb: FireBaseDB,
  invokeFirebaseFunction: InvokeFirebaseFunction,
) {
  const [nextChallengers, _setNextChallengers] = useState(
    undefined as Optional<NextChallenger[]>,
  );

  useEffect(() => {
    onValue(ref(firebaseDb, 'nextChallengers/'), snapshot => {
      const challengers = snapshot.val();
      _setNextChallengers(challengers);
    });
  }, [firebaseDb]);

  const setNextChallengers = useCallback(
    async (challengers?: NextChallenger[]) => {
      await invokeFirebaseFunction('setNextChallengers', challengers);
    },
    [invokeFirebaseFunction],
  );

  const removeNextChallengers = useCallback(async () => {
    await invokeFirebaseFunction('removeNextChallengers');
  }, [invokeFirebaseFunction]);

  return { nextChallengers, setNextChallengers, removeNextChallengers };
}
