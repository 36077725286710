import React from 'react';
import styled from 'styled-components';
import Text from './Generics/Text';
import usePlayer from 'src/hooks/use-player';
import { Team } from 'src/api/use-teams-api';
import Tooltip from './Generics/Tooltip';

interface TeamProps {
  avatarSize?: number;
  fontSize?: number;
  name?: Team['name'];
  players?: Team['players'];
}

export default function TeamUI({
  avatarSize = 32,
  fontSize = 20,
  players,
}: TeamProps) {
  const getPlayer = usePlayer();
  const [player1, player2] = (players ?? []).map(getPlayer);
  const [player1NameShort, player2NameShort] = [player1, player2].map(p => {
    const firstName = p?.name.split(' ')[0] ?? '';
    const short = firstName.substring(0, 9);
    return (short?.length ?? 0) < (firstName.length ?? 0)
      ? `${short}...`
      : firstName;
  });

  return (
    <ContainerCss>
      <ImgContainerCss size={avatarSize} singlePlayer={!player2}>
        <PlayerImgCss size={avatarSize} src={player1?.avatar} />
        {player2 && (
          <PlayerImgCss size={avatarSize} margin={-10} src={player2?.avatar} />
        )}
      </ImgContainerCss>

      <TextContainerCss>
        <Tooltip title={player1?.name}>
          <Text fontSize={fontSize}>{player1NameShort}</Text>
        </Tooltip>
        {player2 && (
          <>
            <Text fontSize={fontSize} color="#EBFD54">
              {'&'}
            </Text>
            <Tooltip title={player2.name}>
              <Text fontSize={fontSize}>{player2NameShort}</Text>
            </Tooltip>
          </>
        )}
      </TextContainerCss>
    </ContainerCss>
  );
}

const ContainerCss = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 5px;
  justify-content: flex-start;
`;

const ImgContainerCss = styled.div<{ singlePlayer: boolean; size: number }>`
  display: grid;
  grid-template-columns: ${p =>
    p.singlePlayer ? `${p.size}px` : `${p.size}px ${p.size}px`};
  align-items: center;
  ${p => p.singlePlayer && 'margin-right: 5px'};
`;

const PlayerImgCss = styled.img<{
  size?: number;
  margin?: number;
}>`
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  border: 1px solid #ebfd54;
  border-radius: 50%;
  margin: 0 ${p => p.margin}px;
`;

const TextContainerCss = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 0.2em;
  align-items: center;
  overflow: hidden;
  @media (max-width: 768px) {
    display: none;
  }
`;
