import React from 'react';
import styled from 'styled-components';
import { TextProps } from './Text';

interface H1Props extends TextProps {
  test?: string;
}

export default function Title({ children, ...params }: H1Props) {
  return (
    <TitleCss
      style={
        {
          ...params,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any
      }
    >
      {children}
    </TitleCss>
  );
}

const TitleCss = styled.p`
  margin: 0;
  font-family: 'Switzer';
  font-style: italic;
  font-weight: 800;
  font-size: 32px;
  line-height: 150%;
  letter-spacing: -0.01em;
  font-feature-settings: 'ss01' on, 'ss02' on;
  color: #4ae1e2;
`;
