import React from 'react';
import styled from 'styled-components';
import Title from './Title';
import { TextProps } from './Text';
import Spinner from './Spinner';

interface ButtonProps extends Omit<TextProps, 'children'> {
  className?: string;
  height: number;
  disabled?: boolean;
  loading?: boolean;
  backgroundColor: string;
  text?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

export default function Button({
  className,
  height,
  disabled,
  backgroundColor,
  text,
  loading,
  children,
  onClick,
  ...textParams
}: ButtonProps) {
  return (
    <ButtonCss
      className={className}
      disabled={disabled || loading}
      height={height}
      backgroundColor={backgroundColor}
      onClick={disabled ? undefined : onClick}
    >
      {loading && <Spinner size={height} />}
      {!loading && text && !children && <Title {...textParams}>{text}</Title>}
      {!loading && !text && children && children}
    </ButtonCss>
  );
}

const ButtonCss = styled.div<{
  height: number;
  disabled?: boolean;
  backgroundColor: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${p => p.height}px;
  background: ${p => (p.disabled ? 'grey' : p.backgroundColor)};
  box-shadow: 0px 0px 1px rgba(18, 23, 35, 0.05),
    0px 8px 16px rgba(18, 23, 35, 0.1);
  border-radius: 6px;
  padding: 16px 16px;
  text-align: center;
  transition: 0.3s;
  ${p =>
    !p.disabled &&
    `&:hover {
    cursor: pointer;
    opacity: 0.54;
  }`}
`;
