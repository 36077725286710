import React, { useMemo } from 'react';
import styled from 'styled-components';
import Text from './Generics/Text';
import usePlayer from 'src/hooks/use-player';
import XIcon from './XIcon';

interface PlayerProps {
  playerId: string;
  avatarSize?: number;
  fontSize?: number;
  canSelect: boolean;
  selected: boolean;
  select: () => void;
}

export default function PlayerUI({
  playerId,
  avatarSize = 32,
  fontSize = 20,
  canSelect,
  selected,
  select,
}: PlayerProps) {
  const getPlayer = usePlayer();
  const player = useMemo(() => getPlayer(playerId), [getPlayer, playerId]);

  return (
    <ContainerCss>
      <PlayerContainerCss
        canSelect={canSelect}
        onClick={canSelect ? select : undefined}
      >
        <PlayerImgCss size={avatarSize} src={player?.avatar} />
        <Text fontSize={fontSize}>{player?.name}</Text>
      </PlayerContainerCss>
      {selected && <XIcon size={24} onClick={canSelect ? select : undefined} />}
    </ContainerCss>
  );
}

const ContainerCss = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-between;
`;

const PlayerContainerCss = styled.div<{ canSelect: boolean }>`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 8px;
  justify-content: flex-start;
  align-items: center;
  transition: 0.3s;
  @media (max-width: 768px) {
    overflow: hidden;
    white-space: nowrap;
  }
  ${p =>
    p.canSelect &&
    `&:hover {
    cursor: pointer;
    opacity: 0.54;
  }`}
`;

const PlayerImgCss = styled.img<{ size?: number; margin?: number }>`
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  border: 1px solid #ebfd54;
  border-radius: 50%;
  margin: 0 ${p => p.margin}px;
`;
