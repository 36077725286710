import React, { useCallback } from 'react';
import styled from 'styled-components';
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import {
  getAuth,
  initializeAuth,
  browserLocalPersistence,
} from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import * as firebaseui from 'firebaseui';
import CurrentGame from './components/CurrentGame';
import Seperator from './components/Generics/Seperator';
import Leaderboard from './components/Leaderboard';
import NextChallengers from './components/NextChallengers';
import TodaysEmbarrassment from './components/TodaysEmbarrassment';
import TodaysGames from './components/TodaysGames';
import FireBaseApiProvider from './contexts/firebase-api';
import FirebaseAuth from './components/FirebaseAuth';
import Log from './components/Log/Log';
import UserMenu from './components/UserMenu';
import IconButton from '@mui/material/IconButton/IconButton';
import NotesIcon from '@mui/icons-material/Notes';
import Tooltip from './components/Generics/Tooltip';

const databaseURL = {
  develop:
    'https://fifa-leaderboard-17ef4-development.europe-west1.firebasedatabase.app/',
  prod: 'https://fifa-leaderboard-17ef4-default-rtdb.europe-west1.firebasedatabase.app/',
};

const firebaseConfig = {
  apiKey: 'AIzaSyBO6ybeMIDr4fiZz1OB6PkPhvgRcrdBHco',
  authDomain: 'fifa-leaderboard-17ef4.firebaseapp.com',
  projectId: 'fifa-leaderboard-17ef4',
  storageBucket: 'fifa-leaderboard-17ef4.appspot.com',
  messagingSenderId: '616568888411',
  appId: '1:616568888411:web:eaf0c9e416f06e2051337e',
  databaseURL: databaseURL.prod,
};

const firebaseApp = initializeApp(firebaseConfig);
const firebaseDb = getDatabase(firebaseApp);
const firebaseFunctions = getFunctions(firebaseApp, 'europe-west1');
const firebaseAuth = initializeAuth(firebaseApp, {
  persistence: browserLocalPersistence,
});
const firebaseAuthUI = new firebaseui.auth.AuthUI(firebaseAuth);

const invokeFirebaseFunction = async (
  functionId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any[] | Record<string, any>,
) => {
  const fn = httpsCallable(firebaseFunctions, functionId);
  const userToken = await getAuth(firebaseApp)
    .currentUser?.getIdTokenResult()
    .then(({ token }) => token);

  return fn({ data, userToken }).catch(console.error);
};

export type FirebaseApp = typeof firebaseApp;
export type FireBaseDB = typeof firebaseDb;
export type FirebaseAuth = typeof firebaseAuth;
export type FirebaseAuthUI = typeof firebaseAuthUI;
export type InvokeFirebaseFunction = typeof invokeFirebaseFunction;

export default function App() {
  const renderLogButton = useCallback((showLog: () => void) => {
    return (
      <IconButtonContainerCss>
        <IconButton style={{ color: 'white' }} size="large" onClick={showLog}>
          <Tooltip title="Show log" placement="top">
            <NotesIcon fontSize="inherit" />
          </Tooltip>
        </IconButton>
      </IconButtonContainerCss>
    );
  }, []);

  return (
    <FireBaseApiProvider
      firebaseAuth={firebaseAuth}
      firebaseAuthUI={firebaseAuthUI}
      firebaseDb={firebaseDb}
      invokeFirebaseFunction={invokeFirebaseFunction}
    >
      <>
        <ContainerCss>
          <UserMenu />
          <CurrentGame />
          <Seperator px={32} />
          <NextChallengers />
          <Seperator px={32} />
          <Leaderboard />
          <Seperator px={32} />
          <TodaysGames />
          <Seperator px={32} />
          <TodaysEmbarrassment />
        </ContainerCss>
        <Log>{renderLogButton}</Log>
      </>
    </FireBaseApiProvider>
  );
}

const ContainerCss = styled.div`
  padding: 60px 24px;
  max-width: 786px;
  margin: 0 auto;
`;

const IconButtonContainerCss = styled.div`
  position: fixed;
  left: 30px;
  bottom: 30px;
  color: white;
  @media (max-width: 768px) {
    display: none;
  }
`;
