import React, { useCallback, useState } from 'react';
import Button from './Generics/Button';
import TitleCss from './Generics/Title';
import Seperator from './Generics/Seperator';
import TeamsWithInput from './HeadsUp/HeadsUpWithInput';
import DialogSlide from './Generics/DialogSlide';
import styled from 'styled-components';
import { useApiContext } from 'src/contexts/firebase-api';

interface FinishGameProps {
  visible: boolean;
  onClose: () => void;
  homeId?: string;
  awayId?: string;
}

export default function FinishGame({
  visible,
  onClose,
  homeId,
  awayId,
}: FinishGameProps) {
  const { setNextGame } = useApiContext();
  const [homeScore, _setHomeScore] = useState<Nullable<number>>(0);
  const [awayScore, _setAwayScore] = useState<Nullable<number>>(0);
  const [awaitingApi, setAwaitingApi] = useState(false);

  const validScoreInput =
    typeof homeScore === 'number' && typeof awayScore === 'number';

  const setHomeScore = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      _setHomeScore(event?.target.value ? Number(event?.target.value) : null);
    },
    [],
  );

  const setAwayScore = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      _setAwayScore(event?.target.value ? Number(event?.target.value) : null);
    },
    [],
  );

  const onSubmit = useCallback(async () => {
    if (!validScoreInput) return;
    setAwaitingApi(true);

    await setNextGame({
      prevGame: { homeScore, awayScore },
    });

    setAwaitingApi(false);
    onClose();
  }, [awayScore, homeScore, onClose, setNextGame, validScoreInput]);

  return (
    <DialogSlide
      disableEscapeKeyDown={awaitingApi}
      sxPaper={{
        backgroundColor: '#2A3765',
      }}
      fullWidth
      open={visible}
      onClose={onClose}
    >
      <ContainerCss>
        <TitleCss fontSize={32} maxWidth="377px">
          Finish Game
        </TitleCss>
        <Seperator px={16} />
        <TeamsWithInput
          homeId={homeId}
          awayId={awayId}
          homeScore={homeScore}
          awayScore={awayScore}
          setHomeScore={setHomeScore}
          setAwayScore={setAwayScore}
        />
        <Seperator px={24} />
        <Button
          height={36}
          backgroundColor="#EBFD54"
          text="Finish Game"
          color="#121213"
          fontSize={24}
          onClick={onSubmit}
          loading={awaitingApi}
          disabled={!validScoreInput}
        />
      </ContainerCss>
    </DialogSlide>
  );
}

const ContainerCss = styled.div`
  padding: 16px;
`;
